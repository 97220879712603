import {descriptorConfig} from '../utils/descriptor-config';
import {NlpShapes} from './nlp-shapes-generated';
import {CustomShapes} from './custom-shapes';

const AliasedShapes: { [key: string]: string } = {};

Object.defineProperty(AliasedShapes, 'add', descriptorConfig(NlpShapes['circle-plus']));
Object.defineProperty(AliasedShapes, 'alarm-call', descriptorConfig(NlpShapes['security-alarm-02']));
Object.defineProperty(AliasedShapes, 'alarm-clock', descriptorConfig(NlpShapes['security-camera-02']));
Object.defineProperty(AliasedShapes, 'alarm', descriptorConfig(NlpShapes['security-alarm']));
Object.defineProperty(AliasedShapes, 'alarm2', descriptorConfig(NlpShapes['security-alarm']));
Object.defineProperty(AliasedShapes, 'animal-bull', descriptorConfig(NlpShapes['bull']));
Object.defineProperty(AliasedShapes, 'animal-cat', descriptorConfig(NlpShapes['cat']));
Object.defineProperty(AliasedShapes, 'animal-cow-02', descriptorConfig(NlpShapes['cow-02']));
Object.defineProperty(AliasedShapes, 'animal-dog', descriptorConfig(NlpShapes['dog']));
Object.defineProperty(AliasedShapes, 'arrow', descriptorConfig(NlpShapes['arrow-right']));
Object.defineProperty(AliasedShapes, 'assistant', descriptorConfig(NlpShapes['person-heart-call']));
Object.defineProperty(AliasedShapes, 'avatar-head', descriptorConfig(NlpShapes['chat-face']));
Object.defineProperty(AliasedShapes, 'avatar', descriptorConfig(NlpShapes['chat-bot']));
Object.defineProperty(AliasedShapes, 'baby-carrier', descriptorConfig(NlpShapes['stroller']));
Object.defineProperty(AliasedShapes, 'bag', descriptorConfig(NlpShapes['attache']));
Object.defineProperty(AliasedShapes, 'balance', descriptorConfig(NlpShapes['scale']));
Object.defineProperty(AliasedShapes, 'barbell', descriptorConfig(NlpShapes['health-weight']));
Object.defineProperty(AliasedShapes, 'bath-duck', descriptorConfig(NlpShapes['duck']));
Object.defineProperty(AliasedShapes, 'bed-light-stand', descriptorConfig(NlpShapes['lamp']));
Object.defineProperty(AliasedShapes, 'boy', descriptorConfig(NlpShapes['person-boy']));
Object.defineProperty(AliasedShapes, 'broken-heart', descriptorConfig(NlpShapes['heart-broken']));
Object.defineProperty(AliasedShapes, 'building', descriptorConfig(NlpShapes['house-flats']));
Object.defineProperty(AliasedShapes, 'bulb', descriptorConfig(NlpShapes['light-bulb']));
Object.defineProperty(AliasedShapes, 'cable', descriptorConfig(NlpShapes['chord']));
Object.defineProperty(AliasedShapes, 'cal', descriptorConfig(NlpShapes['calendar']));
Object.defineProperty(AliasedShapes, 'calendar-02-no-date', descriptorConfig(NlpShapes['calendar-no-date']));
Object.defineProperty(AliasedShapes, 'calendar-02', descriptorConfig(NlpShapes['calendar']));
Object.defineProperty(AliasedShapes, 'call-center', descriptorConfig(NlpShapes['person-call']));
Object.defineProperty(AliasedShapes, 'call-center', descriptorConfig(NlpShapes['person-call']));
Object.defineProperty(AliasedShapes, 'camera', descriptorConfig(NlpShapes['security-camera']));
Object.defineProperty(AliasedShapes, 'car-collision', descriptorConfig(NlpShapes['car-crash-two-cars']));
Object.defineProperty(AliasedShapes, 'car-damage', descriptorConfig(NlpShapes['car-crash-single']));
Object.defineProperty(AliasedShapes, 'car-glass', descriptorConfig(NlpShapes['car-front-damage']));
Object.defineProperty(AliasedShapes, 'car-sedan', descriptorConfig(NlpShapes['car-sedan-side']));
Object.defineProperty(AliasedShapes, 'car-side-stationcar', descriptorConfig(NlpShapes['car-stationcar-side']));
Object.defineProperty(AliasedShapes, 'car-side-van', descriptorConfig(NlpShapes['car-van-side']));
Object.defineProperty(AliasedShapes, 'car-small', descriptorConfig(NlpShapes['car-mini-side']));
Object.defineProperty(AliasedShapes, 'car2', descriptorConfig(NlpShapes['car-sedan-side']));
Object.defineProperty(AliasedShapes, 'carwash', descriptorConfig(NlpShapes['car-wash']));
Object.defineProperty(AliasedShapes, 'cast', descriptorConfig(NlpShapes['broken-leg']));
Object.defineProperty(AliasedShapes, 'charging-station', descriptorConfig(NlpShapes['electricity-case']));
Object.defineProperty(AliasedShapes, 'chat-sender-robot', descriptorConfig(NlpShapes['nordea-website']));
Object.defineProperty(AliasedShapes, 'chat-sender-support', descriptorConfig(NlpShapes['nordea-website']));
Object.defineProperty(AliasedShapes, 'check', descriptorConfig(NlpShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'circle-exclamation', descriptorConfig(NlpShapes['info']));
Object.defineProperty(AliasedShapes, 'circle-question', descriptorConfig(NlpShapes['info']));
Object.defineProperty(AliasedShapes, 'circle-x', descriptorConfig(NlpShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'city-sign', descriptorConfig(NlpShapes['sign-city']));
Object.defineProperty(AliasedShapes, 'clock-forward', descriptorConfig(NlpShapes['clock-around']));
Object.defineProperty(AliasedShapes, 'coffee', descriptorConfig(NlpShapes['hot-drink']));
Object.defineProperty(AliasedShapes, 'cogs', descriptorConfig(NlpShapes['gears']));
Object.defineProperty(AliasedShapes, 'computer-cloud', descriptorConfig(NlpShapes['screen-cloud']));
Object.defineProperty(AliasedShapes, 'computer', descriptorConfig(NlpShapes['computer-screen']));
Object.defineProperty(AliasedShapes, 'conference-call', descriptorConfig(NlpShapes['phone-circle']));
Object.defineProperty(AliasedShapes, 'contacts', descriptorConfig(NlpShapes['health-journal']));
Object.defineProperty(AliasedShapes, 'corn', descriptorConfig(NlpShapes['wheat']));
Object.defineProperty(AliasedShapes, 'court', descriptorConfig(NlpShapes['court-hammer']));
Object.defineProperty(AliasedShapes, 'cow', descriptorConfig(NlpShapes['cow-02']));
Object.defineProperty(AliasedShapes, 'cow2', descriptorConfig(NlpShapes['cow']));
Object.defineProperty(AliasedShapes, 'cow3', descriptorConfig(NlpShapes['bull']));
Object.defineProperty(AliasedShapes, 'credit-cards', descriptorConfig(NlpShapes['credit-card']));
Object.defineProperty(AliasedShapes, 'creditcard', descriptorConfig(NlpShapes['credit-card']));
Object.defineProperty(AliasedShapes, 'creditcards', descriptorConfig(NlpShapes['credit-card']));
Object.defineProperty(AliasedShapes, 'desktop-computer-ad', descriptorConfig(NlpShapes['laptop-ad']));
Object.defineProperty(AliasedShapes, 'desktop-computer-e-mail', descriptorConfig(NlpShapes['laptop-email']));
Object.defineProperty(AliasedShapes, 'desktop-computer-mail', descriptorConfig(NlpShapes['laptop-mail']));
Object.defineProperty(AliasedShapes, 'desktop-computer', descriptorConfig(NlpShapes['laptop']));
Object.defineProperty(AliasedShapes, 'diamond-ring', descriptorConfig(NlpShapes['ring']));
Object.defineProperty(AliasedShapes, 'direct-mail', descriptorConfig(NlpShapes['laptop-email']));
Object.defineProperty(AliasedShapes, 'document-1', descriptorConfig(NlpShapes['document-search']));
Object.defineProperty(AliasedShapes, 'easy-chair', descriptorConfig(NlpShapes['armchair']));
Object.defineProperty(AliasedShapes, 'edit-round', descriptorConfig(NlpShapes['circle-edit']));
Object.defineProperty(AliasedShapes, 'envelope', descriptorConfig(NlpShapes['mail']));
Object.defineProperty(AliasedShapes, 'error', descriptorConfig(NlpShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'europa', descriptorConfig(NlpShapes['europe']));
Object.defineProperty(AliasedShapes, 'excluded', descriptorConfig(NlpShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'expand-map', descriptorConfig(NlpShapes['expand']));
Object.defineProperty(AliasedShapes, 'face-chat', descriptorConfig(NlpShapes['chat-face']));
Object.defineProperty(AliasedShapes, 'flos', descriptorConfig(NlpShapes['nordea-website']));
Object.defineProperty(AliasedShapes, 'first-prize', descriptorConfig(NlpShapes['medal']));
Object.defineProperty(AliasedShapes, 'food-fork-knife', descriptorConfig(NlpShapes['cutlery']));
Object.defineProperty(AliasedShapes, 'future', descriptorConfig(NlpShapes['screen-idea']));
Object.defineProperty(AliasedShapes, 'girl-02', descriptorConfig(NlpShapes['person-girl']));
Object.defineProperty(AliasedShapes, 'girl', descriptorConfig(NlpShapes['person-girl-teen']));
Object.defineProperty(AliasedShapes, 'globe2', descriptorConfig(NlpShapes['globe']));
Object.defineProperty(AliasedShapes, 'graph-down', descriptorConfig(NlpShapes['graph']));
Object.defineProperty(AliasedShapes, 'hand-pointing-right', descriptorConfig(NlpShapes['hand-pointing']));
Object.defineProperty(AliasedShapes, 'hand', descriptorConfig(NlpShapes['hand-wave']));
Object.defineProperty(AliasedShapes, 'head', descriptorConfig(NlpShapes['face']));
Object.defineProperty(AliasedShapes, 'heart-rate', descriptorConfig(NlpShapes['health-heartbeat']));
Object.defineProperty(AliasedShapes, 'holding-hands', descriptorConfig(NlpShapes['hand-holding']));
Object.defineProperty(AliasedShapes, 'hot-drink-cup', descriptorConfig(NlpShapes['hot-drink']));
Object.defineProperty(AliasedShapes, 'house', descriptorConfig(NlpShapes['house-sun']));
Object.defineProperty(AliasedShapes, 'house-sunny', descriptorConfig(NlpShapes['house-sun']));
Object.defineProperty(AliasedShapes, 'hq', descriptorConfig(NlpShapes['house-tree']));
Object.defineProperty(AliasedShapes, 'identificer', descriptorConfig(NlpShapes['health-search']));
Object.defineProperty(AliasedShapes, 'included', descriptorConfig(NlpShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'information-i', descriptorConfig(NlpShapes['info']));
Object.defineProperty(AliasedShapes, 'info-white', descriptorConfig(NlpShapes['info']));
Object.defineProperty(AliasedShapes, 'ipad-vertical-ad', descriptorConfig(NlpShapes['ipad-portrait-ad']));
Object.defineProperty(AliasedShapes, 'ipad-vertical', descriptorConfig(NlpShapes['ipad-portrait']));
Object.defineProperty(AliasedShapes, 'knife-fork', descriptorConfig(NlpShapes['cutlery']));
Object.defineProperty(AliasedShapes, 'locked', descriptorConfig(NlpShapes['lock']));
Object.defineProperty(AliasedShapes, 'loesoere', descriptorConfig(NlpShapes['furniture']));
Object.defineProperty(AliasedShapes, 'logo-flower', descriptorConfig(NlpShapes['nordea-website']));
Object.defineProperty(AliasedShapes, 'magnifying-glass', descriptorConfig(NlpShapes['search']));
Object.defineProperty(AliasedShapes, 'man', descriptorConfig(NlpShapes['person-man']));
Object.defineProperty(AliasedShapes, 'marriag-rings', descriptorConfig(NlpShapes['ring-marriage']));
Object.defineProperty(AliasedShapes, 'mason', descriptorConfig(NlpShapes['bricklayer-wall']));
Object.defineProperty(AliasedShapes, 'mc', descriptorConfig(NlpShapes['motorbike']));
Object.defineProperty(AliasedShapes, 'meeting', descriptorConfig(NlpShapes['table-chairs']));
Object.defineProperty(AliasedShapes, 'minus', descriptorConfig(NlpShapes['circle-minus']));
Object.defineProperty(AliasedShapes, 'money-bag-kroner', descriptorConfig(NlpShapes['money-bag-kr']));
Object.defineProperty(AliasedShapes, 'money-bag', descriptorConfig(NlpShapes['money-bag-dollar']));
Object.defineProperty(AliasedShapes, 'money-note', descriptorConfig(NlpShapes['bill']));
Object.defineProperty(AliasedShapes, 'money-single', descriptorConfig(NlpShapes['bill']));
Object.defineProperty(AliasedShapes, 'money-stack', descriptorConfig(NlpShapes['bills']));
Object.defineProperty(AliasedShapes, 'movables', descriptorConfig(NlpShapes['furniture']));
Object.defineProperty(AliasedShapes, 'my-car', descriptorConfig(NlpShapes['car-mini-side']));
Object.defineProperty(AliasedShapes, 'negative', descriptorConfig(NlpShapes['circle-close']));
Object.defineProperty(AliasedShapes, 'ok-green', descriptorConfig(NlpShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'open', descriptorConfig(NlpShapes['unlock']));
Object.defineProperty(AliasedShapes, 'outdoor', descriptorConfig(NlpShapes['ipad-portrait']));
Object.defineProperty(AliasedShapes, 'paper-1', descriptorConfig(NlpShapes['newspaper']));
Object.defineProperty(AliasedShapes, 'paper-2', descriptorConfig(NlpShapes['newspaper-folded']));
Object.defineProperty(AliasedShapes, 'parasol', descriptorConfig(NlpShapes['sunshade']));
Object.defineProperty(AliasedShapes, 'parking-damage', descriptorConfig(NlpShapes['parking-sign-broken']));
Object.defineProperty(AliasedShapes, 'parking', descriptorConfig(NlpShapes['parking-sign']));
Object.defineProperty(AliasedShapes, 'person-+', descriptorConfig(NlpShapes['person-many']));
Object.defineProperty(AliasedShapes, 'person-care', descriptorConfig(NlpShapes['person-heart-call']));
Object.defineProperty(AliasedShapes, 'person-closeup', descriptorConfig(NlpShapes['person-man']));
Object.defineProperty(AliasedShapes, 'persons', descriptorConfig(NlpShapes['person-two']));
Object.defineProperty(AliasedShapes, 'persons-many', descriptorConfig(NlpShapes['person-many']));
Object.defineProperty(AliasedShapes, 'pin', descriptorConfig(NlpShapes['map-pin']));
Object.defineProperty(AliasedShapes, 'pipe', descriptorConfig(NlpShapes['pipes']));
Object.defineProperty(AliasedShapes, 'place', descriptorConfig(NlpShapes['map-pin']));
Object.defineProperty(AliasedShapes, 'plane-ticket', descriptorConfig(NlpShapes['ticket']));
Object.defineProperty(AliasedShapes, 'plus-circle', descriptorConfig(NlpShapes['circle-plus']));
Object.defineProperty(AliasedShapes, 'positive', descriptorConfig(NlpShapes['circle-check']));
Object.defineProperty(AliasedShapes, 'rectangle', descriptorConfig(NlpShapes['warning-triangle']));
Object.defineProperty(AliasedShapes, 'rifle', descriptorConfig(NlpShapes['weapon']));
Object.defineProperty(AliasedShapes, 'remove', descriptorConfig(NlpShapes['circle-minus']));
Object.defineProperty(AliasedShapes, 'school', descriptorConfig(NlpShapes['house-tree']));
Object.defineProperty(AliasedShapes, 'science', descriptorConfig(NlpShapes['chemistry']));
Object.defineProperty(AliasedShapes, 'screen-light', descriptorConfig(NlpShapes['screen-idea-bright']));
Object.defineProperty(AliasedShapes, 'service', descriptorConfig(NlpShapes['car-service']));
Object.defineProperty(AliasedShapes, 'sign-document', descriptorConfig(NlpShapes['document-sign']));
Object.defineProperty(AliasedShapes, 'smiley-angry', descriptorConfig(NlpShapes['smiley-sad']));
Object.defineProperty(AliasedShapes, 'sofa', descriptorConfig(NlpShapes['couch']));
Object.defineProperty(AliasedShapes, 'stethoscope', descriptorConfig(NlpShapes['health-stethoscope']));
Object.defineProperty(AliasedShapes, 'surveillance', descriptorConfig(NlpShapes['security-camera']));
Object.defineProperty(AliasedShapes, 'table-and-chairs', descriptorConfig(NlpShapes['table-chairs']));
Object.defineProperty(AliasedShapes, 'to-do', descriptorConfig(NlpShapes['document-check-mark']));
Object.defineProperty(AliasedShapes, 'trashcan-1', descriptorConfig(NlpShapes['trash-can']));
Object.defineProperty(AliasedShapes, 'trashcan-2', descriptorConfig(NlpShapes['circle-trash']));
Object.defineProperty(AliasedShapes, 'travel-eiffel-tower', descriptorConfig(NlpShapes['eiffel-tower']));
Object.defineProperty(AliasedShapes, 'travel-globe-destinations', descriptorConfig(NlpShapes['globe-destinations']));
Object.defineProperty(AliasedShapes, 'travel-globe', descriptorConfig(NlpShapes['globe']));
Object.defineProperty(AliasedShapes, 'travel-globus', descriptorConfig(NlpShapes['globe-stand']));
Object.defineProperty(AliasedShapes, 'travel-plane', descriptorConfig(NlpShapes['plane']));
Object.defineProperty(AliasedShapes, 'travel-scenery', descriptorConfig(NlpShapes['cake']));
Object.defineProperty(AliasedShapes, 'travel-suitcase-3', descriptorConfig(NlpShapes['carry-on']));
Object.defineProperty(AliasedShapes, 'travel-ticket', descriptorConfig(NlpShapes['ticket']));
Object.defineProperty(AliasedShapes, 'tree', descriptorConfig(NlpShapes['three']));
Object.defineProperty(AliasedShapes, 'trowel', descriptorConfig(NlpShapes['bricklayer']));
Object.defineProperty(AliasedShapes, 'vacation-backpack', descriptorConfig(NlpShapes['backpack']));
Object.defineProperty(AliasedShapes, 'vacation-house', descriptorConfig(NlpShapes['house-vacation']));
Object.defineProperty(AliasedShapes, 'vacation-sunshade', descriptorConfig(NlpShapes['sunshade']));
Object.defineProperty(AliasedShapes, 'vacation-umbrella', descriptorConfig(NlpShapes['umbrella']));
Object.defineProperty(AliasedShapes, 'van', descriptorConfig(NlpShapes['car-van-side']));
Object.defineProperty(AliasedShapes, 'wand', descriptorConfig(NlpShapes['magic-wand']));
Object.defineProperty(AliasedShapes, 'weather-cloud', descriptorConfig(NlpShapes['cloud']));
Object.defineProperty(AliasedShapes, 'weather-star', descriptorConfig(NlpShapes['star']));
Object.defineProperty(AliasedShapes, 'weather-sun', descriptorConfig(NlpShapes['sun']));
Object.defineProperty(AliasedShapes, 'webcam', descriptorConfig(NlpShapes['security-camera-02']));
Object.defineProperty(AliasedShapes, 'wedding-rings', descriptorConfig(NlpShapes['ring-marriage']));
Object.defineProperty(AliasedShapes, 'woman', descriptorConfig(NlpShapes['person-woman']));
Object.defineProperty(AliasedShapes, 'woman2', descriptorConfig(NlpShapes['person-young-woman']));
Object.defineProperty(AliasedShapes, 'young-woman', descriptorConfig(NlpShapes['person-young-woman']));

export {AliasedShapes};
