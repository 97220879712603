import { IconAlias, IconShapeSources } from './interfaces/icon-interfaces';
import { ShapeTemplateObserver } from './utils/shape-template-observer';

export class FlosIconsApi {
  private iconShapeSources: IconShapeSources = {};

  private static singleInstance: FlosIconsApi;

  constructor() {
    this.iconShapeSources = {};
  }

  static get instance(): FlosIconsApi {
    if (!FlosIconsApi.singleInstance) {
      FlosIconsApi.singleInstance = new FlosIconsApi();
    }

    return FlosIconsApi.singleInstance;
  }

  private validateName(name: string): boolean {
    if (name.length === 0) {
      throw new Error('Shape name or alias must be a non-empty string!');
    }

    if (/\s/.test(name)) {
      throw new Error('Shape name or alias must not contain any whitespace characters!');
    }

    return true;
  }

  private setIconTemplate(shapeName: string, shapeTemplate: string): void {
    const trimmedShapeTemplate = shapeTemplate.trim();

    if (this.validateName(shapeName)) {
      // if the shape name exists, delete it.
      if (this.iconShapeSources[shapeName]) {
        // console.warn(`deleting ${shapeName}`);
        this.iconShapeSources[shapeName] = null;
      }

      this.iconShapeSources[shapeName] = trimmedShapeTemplate;

      ShapeTemplateObserver.instance.emitChanges(shapeName, trimmedShapeTemplate);
    }
  }

  private setIconAliases(templates: IconShapeSources, shapeName: string, aliasNames: string[]): void {
    for (const aliasName of aliasNames) {
      if (this.validateName(aliasName)) {
        Object.defineProperty(templates, aliasName, {
          get: function () {
            return templates[shapeName];
          },
          enumerable: true,
          configurable: true,
        });
      }
    }
  }

  debugAll(): void {
    console.table(this.iconShapeSources);
  }
  debugEach(shape: string): void {
    console.info({ [shape]: this.iconShapeSources[shape] });
  }

  add(icons?: IconShapeSources): void {
    if (typeof icons !== 'object') {
      throw new Error(`The argument must be an object literal passed in the following pattern: 
                { "shape-name": "shape-template" }`);
    }
    for (const shapeName in icons) {
      this.setIconTemplate(shapeName, icons[shapeName]);
    }
  }

  has(shapeName: string): boolean {
    const foundIcon: boolean = !!this.iconShapeSources[shapeName];
    return foundIcon;
  }

  get(shapeName?: string): any {
    // if shapeName is not given, return all icon templates.
    if (!shapeName) {
      return this.iconShapeSources;
    }

    if (typeof shapeName !== 'string') {
      throw new TypeError('Only string argument is allowed in this method.');
    }

    const iconSource = this.iconShapeSources[shapeName];
    return iconSource;
  }

  alias(aliases?: IconAlias): void {
    if (typeof aliases !== 'object') {
      throw new Error(`The argument must be an object literal passed in the following pattern: 
                { "shape-name": ["alias-name", ...] }`);
    }

    for (const shapeName in aliases) {
      if (aliases.hasOwnProperty(shapeName)) {
        if (this.iconShapeSources.hasOwnProperty(shapeName)) {
          // set an alias to the icon if it exists in iconShapeSources.
          this.setIconAliases(this.iconShapeSources, shapeName, aliases[shapeName]);
        } else {
          throw new Error(
            `An icon "${shapeName}" you are trying to set aliases to doesn't exist in the Flos Icons sets!`
          );
        }
      }
    }
  }
}
