import { FlosIconsApi } from './flos-icons-api';
import { ShapeTemplateObserver } from './utils/shape-template-observer';

let flosIconId = 0;
const offScreenSpan = document.createElement('span');
offScreenSpan.className = 'is-off-screen';

let parentConstructor = function () {
  return HTMLElement.apply(this, arguments);
};
if (typeof Reflect === 'object') {
  parentConstructor = function () {
    return (Reflect as any).construct(HTMLElement, arguments, this.constructor);
  };
}

export function FlosIconElement(): any {
  'use strict';
  const _instance = (parentConstructor as any).apply(this, arguments);

  _instance.flosIconUniqId = '_flos_icon_' + flosIconId;
  flosIconId++;

  return _instance;
}

FlosIconElement.observedAttributes = ['shape', 'size', 'title'];

FlosIconElement.prototype = Object.create(HTMLElement.prototype, {
  constructor: { configurable: true, writable: true, value: FlosIconElement },
});

FlosIconElement.prototype.constructor = FlosIconElement;

FlosIconElement.prototype._appendCustomTitle = function () {
  const cloneOffScreenSpan = <HTMLElement>offScreenSpan.cloneNode(false);
  cloneOffScreenSpan.id = this.flosIconUniqId;
  cloneOffScreenSpan.textContent = this.currentTitleAttrVal;
  this.appendChild(cloneOffScreenSpan);
};

FlosIconElement.prototype._setIconSize = function (size: string) {
  if (!Number(size) || Number(size) < 0) {
    this.style.width = null;
    this.style.height = null;
  } else {
    this.style.width = size + 'px';
    this.style.height = size + 'px';
  }
};

FlosIconElement.prototype._normalizeShape = function (value: string): string {
  return value.split(/\s/)[0].toLowerCase();
};

FlosIconElement.prototype.connectedCallback = function () {
  // We want to hide the custom element from screen readers but allow the svg/img content to still be read inline
  // Adding role=none allows the screen reader to skip the custom element as if it were a div or span.
  // https://www.scottohara.me/blog/2018/05/05/hidden-vs-none.html
  if (!this.getAttribute('role')) {
    this.setAttribute('role', 'none');
  }

  if (this.hasAttribute('size')) {
    const sizeAttrValue = this.getAttribute('size');

    if (this.currentSizeAttrVal !== sizeAttrValue) {
      this.currentSizeAttrVal = sizeAttrValue;
      this._setIconSize(sizeAttrValue);
    }
  }

  // Note: the size attribute is irrelevant from the shape template;
  // That's why the size checking placed before detecting changes in shape and title attributes.
  // This means even if the shape is not found, the injected shape will have the user-given size.
  if (this.hasAttribute('shape')) {
    const shapeAttrValue = this._normalizeShape(this.getAttribute('shape'));

    this._shapeTemplateSubscription = ShapeTemplateObserver.instance.subscribeTo(
      shapeAttrValue,
      (updatedTemplate: string) => {
        this._injectTemplate(updatedTemplate);
      }
    );

    this.currentShapeAttrVal = shapeAttrValue;

    if (FlosIconsApi.instance.has(this.currentShapeAttrVal)) {
      const currentShapeTemplate = FlosIconsApi.instance.get(this.currentShapeAttrVal);
      if (currentShapeTemplate === this.currentShapeTemplate) {
        return;
      } else {
        this.currentShapeTemplate = currentShapeTemplate;
      }
    } else {
      this._injectErrorTemplate();
      return;
    }
  }

  if (this.hasAttribute('title')) {
    const titleAttrValue = this.getAttribute('title');

    if (this.currentTitleAttrVal !== titleAttrValue) {
      this.currentTitleAttrVal = titleAttrValue;
    }

    if (!this.currentShapeAttrVal) {
      return;
    }
  }

  this._injectTemplate();
};

FlosIconElement.prototype.attributeChangedCallback = function (
  attributeName: string,
  oldValue: string,
  newValue: string
) {
  if (attributeName === 'size') {
    this._setIconSize(newValue);
  }

  if (attributeName === 'shape') {
    this.currentShapeAttrVal = newValue.split(/\s/)[0];

    // transfer change handler callback to new shape name
    if (this._shapeTemplateSubscription) {
      // remove the existing change handler callback on the old shape name
      this._shapeTemplateSubscription();
      // create a new subscription on the new shape name
      this._shapeTemplateSubscription = ShapeTemplateObserver.instance.subscribeTo(
        this.currentShapeAttrVal,
        (updatedTemplate: string) => {
          this._injectTemplate(updatedTemplate);
        }
      );
    }
    // FlosIconsApi.instance.debugEach(this.currentShapeAttrVal);
    if (FlosIconsApi.instance.has(this.currentShapeAttrVal)) {
      this.currentShapeTemplate = FlosIconsApi.instance.get(this.currentShapeAttrVal);
    } else {
      this._injectErrorTemplate();
      return;
    }
  }
  if (attributeName === 'title') {
    this.currentTitleAttrVal = newValue;

    if (!this.currentShapeAttrVal) {
      return;
    }
  }

  this._injectTemplate();
};

FlosIconElement.prototype.disconnectedCallback = function () {
  // as the icon element is removed from the DOM,
  // remove its listener callback function as well.
  if (this._shapeTemplateSubscription) {
    this._shapeTemplateSubscription();
  }
};

FlosIconElement.prototype._setAriaLabelledBy = function () {
  const existingAriaLabelledBy: string = this.getAttribute('aria-labelledby');
  if (!existingAriaLabelledBy) {
    this.setAttribute('aria-labelledby', this.flosIconUniqId);
  } else if (existingAriaLabelledBy && existingAriaLabelledBy.indexOf(this.flosIconUniqId) < 0) {
    this.setAttribute('aria-labelledby', existingAriaLabelledBy + ' ' + this.flosIconUniqId);
  }
};

FlosIconElement.prototype._injectTemplate = function (shapeTemplate?: string) {
  // Accepting the argument, shapeTemplate, will help us to update the shape template
  // right before the injection.
  if (shapeTemplate && shapeTemplate !== this.currentShapeTemplate) {
    this.currentShapeTemplate = shapeTemplate;
  }
  this.innerHTML = this.currentShapeTemplate;

  if (this.currentTitleAttrVal) {
    this._setAriaLabelledBy();
    this._appendCustomTitle();
  }
};

FlosIconElement.prototype._injectErrorTemplate = function () {
  this.currentShapeTemplate = FlosIconsApi.instance.get('error');
  this._injectTemplate();
};
