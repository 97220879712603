import {flosIconSVG} from "../utils/svg-tag-generator";

/**
 * These shapes replaces the ones in TopdanmarkShapes, because we have the need to style them a little different that the ones generated from the figma exports
 * If adding new shapes, remember to add the shape key to the shapesToSkip array in /scripts/generate-shapes.mjs
 */
const CustomShapes = {
    'avatar-colored': flosIconSVG(
        `<path d="M21.25.75a20.5 20.5 0 00-20.5 20.5 20.423 20.423 0 005.27 13.719l-1.85 7.923.434.377 10.325-2.517a20.471 20.471 0 006.321 1 20.5 20.5 0 0020.5-20.5A20.5 20.5 0 0021.25.75z" fill="#fff" stroke="#75bee9" stroke-miterlimit="10" stroke-width="1.5"/>
  <g transform="translate(6.716 14.293)">
    <circle cx="2.022" cy="2.022" r="2.022" transform="translate(25.023)" fill="#131947"/>
    <circle cx="2.022" cy="2.022" r="2.022" fill="#131947"/>
    <path d="M1.754 11.152a13.452 13.452 0 0012.78 9.249" fill="none" stroke="#131947" stroke-miterlimit="10" stroke-width="2.835"/>
  </g>`,
        '0 0 42.5 44.089'
    )
};

export {CustomShapes};
